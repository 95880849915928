<template>
  <div>
    <!-- 常规运单 -->
    <nav-bar :nav-info="navInfo"></nav-bar>
    <form action="javascript:return ture">
      <van-search
        v-model="searchValue"
        shape="round"
        @search="onSearch"
        clearable
        @clear="ClearSearch"
        placeholder="请输入搜索订单号"
      />
    </form>
    <div class="main">
      <div v-if="dataList.list != null">
        <div
          class="deriver_main"
          v-for="(item, index) in dataList.list"
          :key="index"
          @click="showWayBill(item)"
        >
          <!-- 司机信息 -->
          <div class="driverInfo" v-if="item.driverInfo">
            <div class="driverInfo_detail">
              <p class="driver_name">
                {{ item.driverInfo.driverName }}
                <span class="useYd" v-if="item.orderInfo.length > 0"
                  >有运单</span
                >
                <span class="noYd" v-else>无运单</span>
              </p>
            </div>
            <div class="driverInfo_detail">
              <span>车牌号：{{ item.driverInfo.carNo }}</span>
              <span>电话：{{ item.driverInfo.phone }}</span>
            </div>
          </div>
          <div
            class="fenge"
            v-if="item.orderInfo && item.orderInfo.length > 0"
          ></div>
          <!--订单信息  -->
          <div class="orderInfo">
            <div
              class="mark_ddd"
              v-if="item.orderInfoStatu == false"
              @click.stop="ChangeOrderInfoStatu(true, item)"
            ></div>
            <!-- 折叠按钮 -->
            <div
              class="mark_zk"
              v-else-if="item.orderInfoStatu == true"
              @click.stop="ChangeOrderInfoStatu(false, item)"
            ></div>
            <div v-if="item.orderInfo && item.orderInfo.length > 0">
              <h4>运单信息</h4>
              <div class="orderListBox">
                <div
                  class="orderList"
                  v-for="(infoItem, infoIndex) in showList(item)"
                  :key="infoIndex"
                >
                  <span>{{ infoItem.orderNo }}</span>
                  <span>{{
                    infoItem.createTime | formatDate("YYYY/MM/DD HH:mm:ss")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 计划信息 -->

          <div
            class="orderInfo"
            v-if="$route.query.type && $route.query.type == 'plan'"
          >
            <div
              class="mark_ddd"
              v-if="item.planPrderInfoStatu == false"
              @click.stop="ChangeOrderInfoJhStatu(true, item)"
            ></div>
            <!-- 折叠按钮 -->
            <div
              class="mark_zk"
              v-else-if="item.planPrderInfoStatu == true"
              @click.stop="ChangeOrderInfoJhStatu(false, item)"
            ></div>
            <div
              v-if="item.planOrderInfo && item.planOrderInfo.length > 0"
              @click="showWayBillJh(item)"
            >
              <h4>计划信息</h4>
              <div class="orderListBox">
                <div
                  class="orderList"
                  v-for="(planInfoItem, planInfoIndex) in showListPlan(item)"
                  :key="planInfoIndex + 1"
                >
                  <span>{{ planInfoItem.transportNo }}</span>
                  <span class="orderList_time"
                    ><van-image
                      :src="require('@/assets/img/icon/waybill_time2x.png')"
                    ></van-image
                    >{{
                      planInfoItem.createTime
                        | formatDate("YYYY/MM/DD HH:mm:ss")
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <van-empty
          class="custom-image"
          image="https://img01.yzcdn.cn/vant/empty-image-default.png"
          description="没有找到该订单信息"
        />
      </div>
      <!-- 弹出层 -->
      <van-popup v-model="showPopwaybill"  closeable round position="bottom" >
        <div v-show="qrcodeShow" class="qrcodebox">
          <van-icon @click="closeQrcode" name="cross" size="30" color="#0094ff" />
          <div class="order_no">订单号：{{qrcodeItems.orderNo}}</div>
          <img ref="qrcode" class="qrcodeImg" :src="URL"/>
          <div class="order_userinfo_rigth">
            <div class="order_userinfo_rigth1">
              <div class="order_userinfo_rigth_item">
                <span style="overflow: hidden;">收货人：{{qrcodeItems.receiverName}}</span>
              </div>  
              <div class="order_userinfo_rigth_item"> 
                <span class="tel_icon">{{qrcodeItems.phone}}</span>   
              </div>
            </div>
              <!-- 收货地址 -->
              <div class="order_userinfo_rigth_adress">
                <div class="adress_text">{{qrcodeItems.address}}</div>
              </div>   
              <!-- 参数 -->
          <div class="order_userinfo_rigth1">
            <span>体积：{{qrcodeItems.cargoSize | toFixed(2)}}m³</span>
            <span>重量：{{qrcodeItems.cargoWeight | toFixed(2)}}t</span>
            <span>数量：{{qrcodeItems.cargoNum | toFixed(2)}}</span>
          </div>
        </div>
        </div>
        <div class="detail_title">运单详情</div>
        <div class="orderBox">
          <div class="order" v-for="(item,index) in orderDetails" :key="index">
            <div class="order_tile">
              <div class="order_tile_container">
                <div class="order_title_img1"><van-image :src="require('@/assets/img/icon/cg_order2x.png')"></van-image></div>
                <div class="order_no">订单号：{{item.orderNo}}</div>
                <div class="order_time">{{item.createTime | formatDate('YYYY/MM/DD HH:mm')}}</div>
              </div>
            </div>
            <div class="order_container">
              <div class="fenge"></div>             
              <div class="order_userinfo">
                <!-- check选择框 -->
                <div class="order_userinfo_check"  >
                  <div class="checked">
                    <van-checkbox-group v-model="checkedResult" ref="checkboxGroup">
                      <van-checkbox :name="item"  checked-color="#FD5C0C"></van-checkbox>
                    </van-checkbox-group>
                  </div>
                  
                </div>
                <!-- 右边订单信息 -->
                <div class="order_userinfo_rigth">
                  <div class="order_userinfo_rigth1">
                      <div class="order_userinfo_rigth_item">
                      <span style="overflow: hidden;">收货人：{{item.receiverName}}</span>
                    </div>  
                    <div class="order_userinfo_rigth_item"> 
                      <span class="tel_icon">{{item.phone}}</span>   
                    </div>
                  </div>
                  <!-- 收货地址 -->
                    <div class="order_userinfo_rigth_adress">
                      <div class="adress_text">{{item.address}}</div>
                    </div>   
                  <!-- 参数 -->
                  <div class="order_userinfo_rigth1">
                    <span>体积：{{item.cargoSize | toFixed(2)}}m³</span>
                    <span>重量：{{item.cargoWeight | toFixed(2)}}t</span>
                    <span>数量：{{item.cargoNum | toFixed(2)}}</span>
                  </div>
                </div>
              </div>
            </div>  
             <i class="qrcodeIcon" @click.stop="openQrcode(item)"></i>
          </div>
        </div>
        <!-- 弹出层按钮 -->
        <div class="footer">
           <van-uploader :after-read="afterRead" :before-read="beforeRead">
            <!-- <div class="btn_qx" @click="UploadReceipt">上传回执单</div> -->
            <div class="btn_qx" >上传回执单</div>
          </van-uploader>
          <div class="btn_sub" @click="dotClick">打点</div>
          <div class="btn_sub" @click="CloseOrder">结束运单</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import lrz from "lrz";
import NavBar from "../../components/NavBar/NavBar.vue";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      navInfo: {
        title: "运力监控",
        left: true,
        path: "/regularWaybill",
      },
      searchValue: "",
      showPopwaybill: false,
      checkedResult: [],
      isActiveIndex: 0,
      dataList: [],
      orderDetails: [],
    };
  },
  created() {
    if (this.$route.query.type == "plan") {
      this.navInfo.path = "/planWaybill";
    }
    this.onSearch(this.$route.query.orderNo);
  },
  methods: {
    //生成二维码 
    openQrcode(item) {
      this.qrcodeItems = item
      this.URL = this.qrcodeURL + item.crimg
      this.qrcodeShow = true
      // this.qrcode = new QRCode(this.$refs.qrcode, {
      //   text: URL, //页面地址 ,如果页面需要参数传递请注意哈希模式#
      //   width: 140, // 二维码宽度 （不支持100%）
      //   height: 140, // 二维码高度 （不支持100%）
      //   colorDark: '#000',
      //   colorLight: '#fff',
      //   correctLevel: QRCode.CorrectLevel.H,
      // })
    },
    //关闭清除二维码
    closeQrcode () {
      this.qrcodeShow = false
      this.$refs.qrcode.url = ""
    },
    checkType(index) {
      this.isActiveIndex = index;
    },
    async onSearch(value) {
      // let params = {
      //   orderNo:value
      // }
      // let res= await this.$Api.queryInfoByOrderNo(params)
      let params = {
        userId: this.$store.state.userInfo.deptId,
        page: 1,
        size: 10,
        orderNo: value,
      };
      let res = await this.$Api.transport(params);
      this.dataList = res.data;
    },
    ClearSearch() {
      console.log("清除");
    },
    //显示运单详情弹窗
    showWayBill(item) {
      if (item.orderInfo.length != 0) {
        this.showPopwaybill = true;
        this.orderDetails = item.orderInfo;
        // console.log( this.orderDetails);
        this.checkedResult = [];
      } else {
        Toast("暂无订单信息");
      }
    },
    //上传之前验证
    beforeRead(file) {
      console.log(file);
      if (file.type != "image/jpeg" && file.type != "image/png") {
        Toast("请上传 jpg/png 格式图片");
        return false;
      }
      return true;
    },
    dataURLtoFile(dataurl, filename) {
      // 将base64转换为file文件
      // 参数dataurl base64字符串
      // 参数filename 图片名称
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    //上传回执单图片
    async afterRead(files) {
      if (this.checkedResult.length <= 0) {
        Toast.fail("请先选择订单");
      } else if (this.checkedResult.length > 1) {
        Toast("回执单不能批量进行操作哦");
      } else {
        let param = new FormData();
        if (files.file.size > 1048576) {
          //根据图片字节去判断是否压缩图片 1M
          lrz(files.file, { quality: 0.5 }).then(async (rst) => {
            console.log(rst);
            if (rst.size > 1048576) {
              Toast.fail("您上传的文件太大");
              return false;
            }
            let base64 = this.dataURLtoFile(rst.base64, files.file.name);
            param.append("file", base64);
            let res = await this.$Api.uploadPicture(
              this.checkedResult[0].phone,
              param
            );
            if (res.data.code == 200) {
              Toast.success("上传回执单成功");
            } else {
              Toast.fail("上传失败，请重新上传");
            }
          });
        } else {
          param.append("file", files.file);
          let res = await this.$Api.uploadPicture(
            this.checkedResult[0].phone,
            param
          );
          console.log(res);
          if (res.data.code == 200) {
            Toast.success("上传回执单成功");
          } else {
            Toast.fail("上传失败，请重新上传");
          }
        }
      }
    },
    //结束订单
    async CloseOrder() {
      if (this.checkedResult.length <= 0) {
        Toast("请先选择订单");
      } else {
        let arr = [];
        this.checkedResult.map((item) => {
          arr.push(item.waybillNo);
        });
        let strArr = arr.join(",");
        let params = {
          waybillNo: strArr,
        };
        let res = await this.$Api.endWaybillByWaybillNo(params);
        console.log(res);
        if (res.data == true) {
          this.showPopwaybill = false;
          Toast.success("结束订单成功");
          this.$router.push(`/regularWaybill`);
        } else {
          Toast("结束订单失败");
        }
      }
    },
    //点击显示隐藏更多
    showList(item) {
      // console.log(item);
      if (item.orderInfoStatu == false) {
        var showList = [];
        if (item.orderInfo.length > 3) {
          for (let i = 0; i < 3; i++) {
            showList.push(item.orderInfo[i]);
          }
        } else {
          showList = item.orderInfo;
        }
        return showList;
      } else {
        return item.orderInfo;
      }
    },
    //点击显示隐藏更多Plan
    showListPlan(item) {
      // console.log(item);
      if (item.planPrderInfoStatu == false) {
        let showList = [];
        if (item.planOrderInfo.length > 3) {
          for (let i = 0; i < 3; i++) {
            showList.push(item.planOrderInfo[i]);
          }
        } else {
          showList = item.planOrderInfo;
        }
        return showList;
      } else {
        return item.planOrderInfo;
      }
    },

    //点击mark折叠展开事件
    ChangeOrderInfoStatu(status, item) {
      item.orderInfoStatu = status;
      this.showList(item);
    },
    ChangeOrderInfoJhStatu(status, item) {
      item.planPrderInfoStatu = status;
      this.showList(item);
    },
  },
  beforeRouteLeave(to, from, next) {
    console.log(to);
    if (to.name == "PlanWaybill") {
      to.meta.KeepAlive = true;
      next();
    } else {
      next();
    }
  },
};
</script>
<style lang="scss"  scoped>
.main {
  height: 84vh;
  overflow: scroll;
  font-size: 0.37rem;
  // height: 18rem;
  .deriver_main {
    width: 9.14rem;
    margin: 0 0 0.23rem 0.43rem;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 0.64rem;
  }
  .driverInfo {
    .driverInfo_detail {
      position: relative;
      .driver_name {
        height: 0.64rem;
        font-size: 0.43rem;
        background-image: url("../../assets/img/icon/waybill_user.png");
        background-repeat: no-repeat;
        padding-left: 0.64rem;
        line-height: 0.69rem;
        span {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 0.32rem;
          width: 1.15rem;
          height: 0.45rem;
          line-height: 0.45rem;
          text-align: center;
          border-radius: 0.1rem;
        }
        .useYd {
          padding: 0;
          color: #fb6600;
          background-color: rgba($color: #fb6600, $alpha: 0.2);
        }
        .noYd {
          padding: 0;
          color: #999999;
          background-color: rgba($color: #999999, $alpha: 0.2);
        }
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        flex: 1;
        padding: 0.16rem 0;
      }
      span:nth-child(2) {
        text-align: right;
      }
    }
  }
  .order {
    padding: 0.13rem 0 0.3rem 0.38rem;
    border-bottom: 5px solid #f5f5f5;
  }
  .orderInfo {
    position: relative;
    overflow: visible;
    .mark_ddd {
      z-index: 999;
      position: absolute;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0.64rem;
      height: 0.64rem;
      background-repeat: no-repeat;
      background-size: 0.64rem;
      background-image: url("../../assets/img/icon/waybill_ddd.png");
    }
    .mark_zk {
      z-index: 999;
      position: absolute;
      width: 0.42rem;
      height: 0.42rem;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
      background-repeat: no-repeat;
      background-size: 0.42rem;
      background-image: url("../../assets/img/icon/zk_zd.png");
    }
  }
  .orderList {
    h3 {
      font-size: 0.32rem;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 0.33rem;
      height: 0.45rem;
      background-size: 0.64rem;
    }
    span:nth-child(2) {
      text-align: right;
      width: 3.38rem;
      padding: 0.16rem 0 0 0.45rem;
      background-image: url("../../assets/img/icon/waybill_time.png");

      background-repeat: no-repeat;
    }
    span:nth-child(1) {
      flex: 1;
      padding: 0.16rem 0 0 0.64rem;
      background-image: url("../../assets/img/icon/waybill_order.png");
      background-repeat: no-repeat;
    }
  }
}
.fenge {
  height: 0.03rem;
  margin-top: 0.27rem;
  background: #f5f5f5;
}

.order {
  font-size: 0.37rem;
  .order_container {
    // background-color: pink;
    background-color: #ffffff;
    // height: 3.28rem;
    border-radius: 0.3rem;
  }
  .order_container_jh {
    // background-color: pink;
    // height: 4.03rem;
    background-color: #ffffff;
    // height: 3.28rem;
    border-radius: 0.3rem;
  }
  .order_container_time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      padding: 5px 0.27rem;
      color: #999999;
    }
    span:nth-child(2) {
      padding-right: 0.61rem;
    }
  }
  .order_userinfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .order_userinfo_check {
      width: 1.04rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .checked {
        width: 0.5rem;
        height: 0.5rem;
        /deep/.van-checkbox__icon .van-icon {
          width: 0.5rem;
          height: 0.5rem;
        }
      }
    }
    .order_userinfo_rigth {
      flex: 1;
      height: 100%;
      padding-right: 0.3rem;
      .order_userinfo_rigth1 {
        padding-top: 0.21rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.53rem;
        span {
          color: #999999;
        }
        .order_userinfo_rigth_item {
          display: flex;
          justify-content: left;
          align-items: center;
          span {
            color: #000000;
            height: 0.64rem;
            line-height: 0.64rem;
            padding-left: 0.6rem;
            background-size: 0.32rem;
            background: url("../../assets/img/icon/order_user.png") no-repeat;
          }
          .tel_icon {
            background-size: 0.32rem;
            background: url("../../assets/img/icon/order_tel.png") no-repeat;
          }
        }
      }
    }
  }
}
.order_userinfo_rigth_adress {
  // height: 0.64rem;
  background-size: 0.32rem;
  background: url("../../assets/img/icon/order_adress.png") no-repeat;
  padding-top: 0.2rem;
  position: relative;
  margin-bottom: -0.3rem;
  .adress_text {
    padding-left: 0.6rem;
    line-height: 0.6rem;
    margin-top: -0.2rem;
    // line-height: 0.3rem;
  }
}
.order_tile_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #999999;
  .order_title_img1 {
    width: 0.64rem;
    height: 0.64rem;
  }
  .order_title_img2 {
    width: 1.55rem;
    height: 0.48rem;
    padding-left: 2.12rem;
  }
  .order_no {
    color: #999999;
    width: 5.44rem;
    height: 0.53rem;
    font-size: 0.37rem;
    line-height: 0.53rem;
    // margin-left: 0.64rem;
  }
  .order_time {
    padding-right: 0.28rem;
  }
}
.qrcodeIcon {
  position: absolute;
  bottom: 0.3rem;
  left: 0.45rem;
  width: 20px;
  height: 20px;
  background: url('../../assets/img/icon/qrcodeIcon.png');
  background-size: 100%
}
.qrcodebox {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.2);
    z-index: 2;
    padding-top: 2rem;
    box-sizing: border-box;
    /deep/ .van-icon-cross {
      position: absolute;
      top: 0.2rem;
      right: 0.2rem;
    }
    .qrcodeImg {
      margin: 1rem 0 0.6rem 0;
    }
    .order_userinfo_rigth {
      flex: 1;
      height: 100%;
      padding: 0.3rem;
      .order_userinfo_rigth1{
        display: flex;
        justify-content: space-between;
        align-items: center;
          span {
            color: #999999;
          }
        .order_userinfo_rigth_item {
          display: flex;
          justify-content: left;
          align-items: center;
          span {
            color: #000000;
            height: 0.64rem;
            line-height: 0.64rem;
            padding-left: 0.6rem;
            background: url('../../assets/img/icon/order_user2x.png') no-repeat ;
            background-size: 0.64rem;
          }
          .tel_icon {
            background: url('../../assets/img/icon/order_tel2x.png') no-repeat ;
            background-size: 0.64rem;
          }
        }
      }
      .order_userinfo_rigth_adress {
        background: url('../../assets/img/icon/order_adress2x.png') no-repeat ;
        background-size: 0.64rem;
        position: relative;
        margin: 0.2rem 0;
        .adress_text {
          padding-left: 0.6rem;
          line-height: 0.6rem;
          margin-top: -0.2rem;
        }
      }
    }

}
.detail_title {
  padding: 0.4rem 0 0.2rem 0;
  height: 0.59rem;
  line-height: 0.59rem;
  text-align: center;
  font-size: 0.43rem;
}
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 0.1rem 0.1rem 0.1rem #cccccc;
  background: #ffffff;
  height: 1.33rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  div {
    width: 3.73rem;
    height: 0.85rem;
    color: #ffffff;
    line-height: 0.85rem;
    text-align: center;

    border-radius: 0.85rem;
  }
  .btn_qx {
    background: #0094ff;
  }
  .btn_sub {
    background: #fb6600;
  }
}

// screen弹出框
.screen_title {
  font-size: 0.43rem;
  text-align: center;
  line-height: 0.59rem;
  padding-top: 0.27rem;
}
h4 {
  font-size: 0.37rem;
  font-weight: 400;
  line-height: 0.53rem;
  padding: 0.27rem 0 0.17rem 0.27rem;
}
h3 {
  font-size: 0.37rem;
}
.screen_type {
  height: 0.8rem;
  font-size: 0.32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .screen_type_item {
    margin: 0.3rem;
    width: 2.35rem;
    height: 100%;
    background: #f5f5f5;
    text-align: center;
    line-height: 0.8rem;
    border-radius: 0.8rem;
    color: #999999;
  }
  .screen_type_check {
    color: #fb6600;
    background: rgba($color: #fb6600, $alpha: 0.1);
  }
}
.screen_orderNo,
.screen_ys_orderNo,
.screen_ys_orderNo {
  width: 9.15rem;
  height: 0.8rem;
  border: #fb6600;
  margin: 0 auto;
}
.van-cell {
  border-radius: 0.8rem;
  padding: 0.15rem 0.4rem;
}
.screen_sub {
  height: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.45rem;
  div {
    height: 100%;
    width: 2.13rem;
    border-radius: 0.85rem;
    text-align: center;
    color: #ffffff;
    font-size: 0.43rem;
    line-height: 0.85rem;
    background: #fb6600;
  }
}
.screen_time {
  width: 9.15rem;
  height: 0.8rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    width: 4.21rem;
    /deep/.el-input__inner {
      border-radius: 0.8rem;
      height: 0.8rem;
      background: #f5f5f5;
      border: 0;
    }
    /deep/.el-input__icon {
      opacity: 0;
    }
  }
}

/deep/.van-popup {
  min-height: 55%;
  max-height: 85%;
  overflow: hidden;
}
.orderBox {
  padding-bottom: 2.33rem;
  max-height: 10rem;
  overflow: scroll;
}
>>> .el-picker-panel {
  z-index: 9999;
}

.custom-image .van-empty__image {
  width: 90px;
  height: 90px;
}
</style>